

<template>
  <div class="banner">
    <a href="https://t.me/jjd4949" target="_blank">
      <img alt="" src="../../../assets/images/banner/tel.jpg">
    </a>
    <a href="https://평생제주도.com" target="_blank">
      <img alt="" src="../../../assets/images/banner/www.jpg">
    </a>
  </div>

</template>
<script>
export default {
  name: "RightBannerComp"
}
</script>
<style scoped>
  .banner{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

  }
  .banner a{}
  .banner img{
    width: 100%;

  }
</style>