<template>
  <div class="userinfo_wrap">
    <div class="rankimg">
      <img :src="$store.state.userInfo.rank.imageUrl" width="80">
    </div>
    <div class="uif">
      <div class="nick">
        <span class="n"><i class="fa fa-user"></i> {{ $store.state.userInfo.nickname }}</span>
        <span class="btn">
          <router-link tag="button" class="" style="display: block" to="/front/userinfo">회원정보</router-link>
        </span>
        <span class="btn">
          <button class="" @click="logout" style="">로그아웃</button>
        </span>
      </div>
      <div class="money" @click="initUserInfo">
        <div class="m1">
          <img src="../../../assets/images/icon/topbar/userinfo/머니.png" alt="" style="width: 18px">
        </div>
        <div class="m2">
          보유머니
        </div>
        <div class="m3">
          <span>{{$store.state.userInfo.cash|comma}}</span>
          <i class="fa fa-refresh" @click="initUserInfo"></i>
        </div>
      </div>
      <div class="money" @click="changePoint">
        <div class="m1">
          <img src="../../../assets/images/icon/topbar/userinfo/포인트.png" alt="" style="width: 18px">
        </div>
        <div class="m2">
          포인트
        </div>
        <div class="m3">
          <span>{{$store.state.userInfo.userPoint|comma}}</span>
          <i class="fa fa-refresh"></i>
        </div>
      </div>
      <div class="money" @click="refreshCasinoCash()">
        <div class="m1">
          <img src="../../../assets/images/icon/topbar/userinfo/카지노칩.png" alt="" style="width: 18px">
        </div>
        <div class="m2">
          카지노
        </div>
        <div class="m3">
          <span @click="initUserInfo">{{$store.state.userInfo.casinocash|comma}}</span>
          <i class="fa fa-refresh"></i>
        </div>
      </div>
      <div class="money" @click="initUserInfo">
        <div class="m1">
          <img src="../../../assets/images/icon/topbar/userinfo/머니.png" alt="" style="width: 18px">
        </div>
        <div class="m2">
          베팅중머니
        </div>
        <div class="m3">
          <span @click="initUserInfo">{{$store.state.userInfo.onbetmoney|comma}}</span>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {INIT_USERINFO, RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING, RECEIVE_USER_INFO} from "@/store/mutation-types";
import {changePoint,logout} from "@/network/userRequest";
import {getAccountBalance, getAccountBalance2} from "@/network/casinoHonorRequest";
import {comma} from "@/filters";

export default {
  name: "RightUserInfoComp",
  data() {
    return {
      user: {
        username: '',
        passwd: '',
      },
    }
  },
  methods: {
    comma,
    logout() {
      this.$swal({
        title: '로그아웃 하시겠습니까 ?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          this.$store.commit(INIT_USERINFO);
          window.sessionStorage.clear();
          this.$store.state.isLogin = false;
          this.$router.replace("/memberlogin")
          this.$store.state.uncheckedMessageCount = 0;
          logout();
        }
      })

    },
    initUserInfo() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      this.$store.dispatch('actionUserInfo')
      setTimeout(() => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
      }, 1000)
    },
    changePoint() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      changePoint().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (!res.data.success) {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        } else {
          this.initUserInfo()
        }
      })
    },
    refreshCasinoCash() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      setTimeout(()=>{
        getAccountBalance().then(res => {
          this.$store.commit(RECEIVE_HIDE_LOADING)
          this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
        })
      },1000);
    },
    refreshCasinoCash2() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getAccountBalance2().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        this.$set(this.$store.state.userInfo, 'slotcash', res.data.data)
      })
    }
  },
  created() {

  }
}
</script>
<style scoped>
.userinfo_wrap {
  width: 100%;
  min-height: 8rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: .5rem;
  background-color: #15161b;
  border-radius: .5rem .5rem 0 0;
  font-size: 1.1rem;
}

.userinfo_wrap .rankimg {
  width: 25%;
  box-sizing: border-box;
  padding: 0 .2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userinfo_wrap .rankimg img{
    max-width: 6rem;
}

.userinfo_wrap .uif{
  width: 75%;
  min-height: 10rem;
  box-sizing: border-box;
  padding: .5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

}

.userinfo_wrap .uif .nick {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.userinfo_wrap .uif .nick .n {
  width: 40%;
  color: #ffff47;
}
.userinfo_wrap .uif .nick .btn{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userinfo_wrap .uif .nick .btn button{
  font-size: 1rem;
  width: 95%;
  line-height: 2rem;
  background-color: #55738f;
  border: 0;
  cursor: pointer;
  border-radius: 3px;
  color: #fff9ff;
}

.userinfo_wrap  .uif .money{
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  color: #fff9ff;
  line-height: 2.8rem;
}
.userinfo_wrap .uif .money .m1{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 10%;
}
.userinfo_wrap .uif .money .m1 img{
  width: 100%;
}
.userinfo_wrap .uif .money .m2{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 35%;
  padding-left: .5rem;
}
.userinfo_wrap .uif .money .m3{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 65%;
}
.userinfo_wrap .uif .money .m3 span{
  color: #ff973e;
}
.userinfo_wrap .uif .money .m3 i{
  margin-left: .5rem;
  color: #d3d2d3;
}

</style>